.side-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 8px;
  ul {
    background: #1a1c1e;
    color: white;
  }
  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #606666;
    color: white;
    svg {
      path {
        fill: white;
      }
    }
  }
  .ant-menu-light .ant-menu-item-selected {
    color: white;
    svg {
      path {
        fill: white;
      }
    }
  }
  .ant-menu-item {
    border-radius: 2px;
  }
  .ant-menu-light {
    color: #7b7d7f;
  }
  .profile-data {
    .user-detail {
      max-width: 150px;

      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .email {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #8a8c8e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-menu-title-content {
    font-size: 16px;
  }
}

.ant-layout-sider-light #logo {
  margin-bottom: 10px;
  border-bottom: 2px solid #252728;
  svg {
    width: 170px;
    height: 36px;
    path {
      fill: white;
    }
  }
}
.desktop-no-preview {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-header {
  width: 100%;
  top: 64px !important;
  .extra-header-btns {
    padding: 12px;
    /* @media (max-width: 425px) {
      justify-content: space-around;
    } */
    .ant-btn {
      height: 40px;
      width: 100%;
      font-size: 16px;
      svg {
        font-size: 16px;
      }
    }
  }

  .logout-header-btn {
    padding: 12px;
    justify-content: flex-end;
    display: flex;
    .ant-btn {
      font-size: 16px;
      border: none;
      box-shadow: none;
    }
  }
}

.mobile-header .ant-dropdown-menu {
  border-radius: 0px;
  .ant-dropdown-menu-item {
    font-weight: 400;
  }
  .ant-dropdown-menu-title-content {
    font-size: 16px;
  }
  .ant-dropdown-menu-item-divider {
    margin: 4px 10px;
  }
}

.dropdown-div {
  svg {
    font-size: 24px;
    path {
      fill: #000;
    }
  }
}

.app-layout {
  .ant-layout-content {
    background-color: #f2f4f7;
  }
}

.footer-content {
  background-color: #ffffff;
  padding: 18px;
}

.app-version {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
