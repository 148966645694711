#root {
  height: 100vh;
}
/********** margin padding function start here **************/
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.p-4 {
  padding: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.m-4 {
  margin: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.p-5 {
  padding: 5px;
}
.pt-5 {
  padding-top: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.pb-5 {
  padding-bottom: 5px;
}
.m-5 {
  margin: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.p-6 {
  padding: 6px;
}
.pt-6 {
  padding-top: 6px;
}
.pl-6 {
  padding-left: 6px;
}
.pr-6 {
  padding-right: 6px;
}
.pb-6 {
  padding-bottom: 6px;
}
.m-6 {
  margin: 6px;
}
.mt-6 {
  margin-top: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.mr-6 {
  margin-right: 6px;
}
.mb-6 {
  margin-bottom: 6px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.p-10 {
  padding: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.p-12 {
  padding: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.m-12 {
  margin: 12px;
}
.mt-12 {
  margin-top: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.p-15 {
  padding: 15px;
}
.pt-15 {
  padding-top: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}
.m-15 {
  margin: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
/********** margin padding function end here **************/
.m-hide {
  display: block;
}
@media only screen and (max-width: 767px) {
  .m-hide {
    display: none;
  }
}
.d-hide {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-hide {
    display: block;
  }
}
.wrapper {
  padding: 32px;
  padding-bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    font-size: 14px;
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 16px;
    padding-bottom: 0;
  }
}
.responsive-table {
  width: 100%;
  overflow: auto;
}
.ant-table .ant-table-tbody .anticon {
  font-size: 20px;
  padding: 0 4px;
}
.ant-table .ant-table-tbody .ant-table-cell {
  font-size: 15px;
}
.ant-table .ant-table-thead .ant-table-cell {
  text-align: center;
}
.ant-table .ant-table-tbody .ant-btn {
  margin: 0;
  padding: 0;
  height: auto;
}
.pointer {
  cursor: pointer;
}
.grab {
  cursor: grab;
}
/********** text alignment start here **************/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
.text-break {
  word-break: break-all;
}
/********** text alignment end here **************/
/********** flex box class start here **************/
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.flex-1 {
  flex: 1;
}
/********** flex box class end here **************/
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.h-40 {
  height: 40px;
}
.site-result-demo-error-icon {
  color: #ff4d4f;
}
.b-0 {
  border: none;
}
/*position class start here*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
/*position class end here*/
.header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-card-head-title {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sticky-action-form .ant-page-header-heading {
    padding: 0;
    min-height: 48px;
  }
}
#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #121d45;
}
#logo img {
  height: 32px;
}
.delete-icon {
  color: #e92424;
  cursor: pointer;
}
/*table draggable css start here*/
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging td {
  padding: 16px;
}
.row-dragging .drag-visible {
  visibility: visible;
}
.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}
.trigger {
  cursor: pointer;
  transition: color 0.3s;
}
.trigger svg {
  font-size: 24px;
  color: #000000;
}
/*sidebar custom less start here*/
.responsive-logo img {
  width: 44px;
}
.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0 !important;
  left: 0;
}
.ant-layout .ant-layout-sider-light {
  background: #1a1c1e;
  min-width: 240px !important;
  max-width: 240px !important;
  width: 200px;
}
@media only screen and (max-width: 540px) {
  .ant-layout .ant-layout-sider-light {
    min-width: 210px !important;
  }
}
.ant-layout .ant-layout-sider-light #logo {
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
@media only screen and (max-width: 767px) {
  .ant-layout-sider {
    width: 0;
    max-width: 0;
    position: fixed !important;
    height: 100%;
    left: -240px;
    z-index: 12;
  }
}
.ant-layout-sider li.ant-menu-item {
  padding-inline: 16px;
}
.ant-layout-sider li.ant-menu-item .ant-menu-title-content {
  opacity: unset;
  margin-inline-start: 18px;
}
.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ant-layout.ant-layout-has-sider {
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  .ant-layout-header {
    padding: 0 16px !important;
  }
}
header.ant-layout-header.site-layout-sub-header-background.app-header {
  height: 64px;
  padding: 0 32px;
  line-height: 64px;
  background: #fff;
}
@media only screen and (max-width: 767px) {
  .overlay-disable {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.6;
    z-index: 11;
  }
}
/*sidebar custom less end here*/
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
:hover::-webkit-scrollbar-thumb {
  border-left: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
}
.ant-body-scroll .ant-card-head {
  position: relative;
  z-index: 1;
}
.ant-body-scroll .ant-page-header-heading {
  min-height: 48px;
}
.ant-body-scroll > .ant-card-body {
  flex: 1 1;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.ant-body-scroll > .ant-card-body .card-body-wrapper {
  height: 100%;
  overflow: auto;
  padding: 24px;
}
.ant-card-small > .ant-card-body .card-body-wrapper {
  padding: 12px;
}
.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}
.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
}
.title svg {
  margin-right: 5px;
  font-size: 16px;
}
/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
}
/*fleg dropdown css end here*/
.breadcrumb-title {
  margin: 15px 0px;
}
.breadcrumb-title ol {
  align-items: center;
}
.breadcrumb-title a {
  height: 100%;
}
.action-buttons {
  cursor: pointer;
}
.ant-modal {
  padding: 26px;
}
.ant-modal .ant-modal-content {
  border-radius: 4px;
}
.ant-modal .ant-modal-close {
  top: 25px;
}
.ant-modal .ant-modal-close svg {
  font-size: 18px;
  color: #000000f2;
}
.ant-modal .ant-modal-header {
  border-bottom: 2px solid #e5e7eb;
}
.ant-modal .ant-btn {
  padding: 16px 32px 16px 32px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.ant-modal p {
  color: #4b5563;
  font-weight: 500;
}
.search-component {
  width: 100%;
  border-radius: 1px;
  height: 40px;
}
.search-component svg {
  fill: #c1c1c1;
  font-size: 16px;
}
.search-component:hover {
  border-color: #d9d9d9;
}
.search-component:focus,
.search-component:focus-within {
  border-color: #d9d9d9;
  box-shadow: unset;
}
.inactive-row {
  background-color: #f2f4f7;
  color: #8c8c8c;
}
.font-600 {
  font-weight: 600;
}
.table-component .ant-table-thead > tr > th {
  font-size: 16px;
  color: #4c4c4c;
}
.table-component .ant-table-thead > tr > .ant-table-cell {
  border-bottom: 1px solid #ceceda;
}
.table-component .ant-table {
  border: 1px solid #ceceda;
  border-radius: 4px;
}
.required-sign {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  visibility: unset;
}
p.error-message {
  color: #ff4d4f;
  font-weight: 400;
  margin: 0;
  margin-bottom: 24px;
}
.common-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 230px);
  min-height: 400px;
}
.change-password-btn span {
  text-decoration: underline;
}
/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
/*typography css end here*/
/**https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less**/
